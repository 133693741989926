import React from 'react';
import styled from 'styled-components';
import { StepTitle } from '../configurator';
import { numberChecker } from './../../checkout/StepWrapper';


const SelectCard = styled.div`
  white-space: pre-wrap;
  padding: 1.5rem;
  margin: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: start;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(51, 1, 109, 0.1);
  border-radius: 8px;
  border: ${props => props.isSelected ? '2px solid #60FEB2' : '2px solid transparent'};
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 30px rgba(96, 254, 178, 1);
  }

  @media screen and (min-width: 991px) {
    margin: 1rem 1rem 1rem 0rem;
  }
`;

const SingleMultiSelect = ({ dispatch, dispatchHandle, isSingle, selectOptions, title, hasOptionalInput, tvCount, currentStep, link }) => {
  window.history.pushState("", "", '/konfigurator/' + link + "/" + currentStep);
  return (
    <>
      <StepTitle title={title} />
      <div className=''>
        {selectOptions.map(({ option, isSelected }, index) => (
          <SelectCard
            key={index}
            isSelected={isSelected}
            onClick={() => {
              dispatch({
                type: isSingle ? 'update_list_item_single' : 'update_list_item',
                index: index,
                dispatchHandle: dispatchHandle,
                nullTvCount: hasOptionalInput,
              });
            }}
          >
            <div className='d-inline-block'>
              <h3><span className={'mr-2 icon checkbox ' + (isSelected && 'active')}></span>{option}</h3>
            </div>
          </SelectCard>
        ))}

        {hasOptionalInput && (
          <>
            <SelectCard
              isSelected={tvCount !== ''}
              onClick={() => {
                dispatch({ type: 'tv_value_change', value: '', dispatchHandle: dispatchHandle })
              }}
            >
              <div className='d-inline-block'>
                <h3>
                  <span className={'mr-2 icon checkbox ' + (tvCount !== '' && 'active')}></span>
                  <input
                    value={tvCount}
                    className='clear'
                    placeholder="Въведете до 5 броя"
                    onFocus={(e) => e.target.placeholder = ""}
                    onBlur={(e) => e.target.placeholder = "Въведете до 5 броя"}
                    onChange={(v) => {
                      dispatch({ type: 'tv_value_change', value: numberChecker(v.currentTarget.value) ? v.currentTarget.value : 1, dispatchHandle: dispatchHandle })
                    }}
                  />
                </h3>
              </div>
            </SelectCard>
            <small>При 6 и повече приемници, моля, позвънете на <a href='tel:0882991611'>088 299 1611</a> за индивидуална оферта.</small>
          </>
        )}
      </div>
    </>
  )
}

export default SingleMultiSelect;