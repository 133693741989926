import React, { useState } from 'react';
import styled from 'styled-components';
import { StepTitle } from '../configurator';

import tvNetImg from '../../../images/configurator/step1/tv+net.svg'
import netImg from '../../../images/configurator/step1/net.svg'

const Step1Card = styled.div`
  width: 300px;
  min-height: 180px;
  text-align: center;
  padding: 1.5rem;
  margin: 0.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(51, 1, 109, 0.1);
  border: ${props => props.isSelected ? '2px solid #60FEB2' : '2px solid transparent'};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 30px rgba(96, 254, 178, 1);
  }
`;


const Step1 = ({ dispatch }) => {
  const [selected, setSelected] = useState(0);
  return (
    <>
      <StepTitle title={'Изберете пакет, с който да започнем'}></StepTitle>
      <div className='row d-flex justify-content-center'>
        <Step1Card
          isSelected={selected === 1}
          onClick={() => {
            setSelected(1);
            dispatch({
              type: 'step_result',
              step_field: 'step1Results',
              step_result: 'ТВ + Интернет'
            })
          }}
        >
          <img src={tvNetImg} className='img-fluids mb-3' style={{ height: '70px', width: 'auto' }} alt='ТВ + интернет' />
          <h3>Телевизия и интернет</h3>
        </Step1Card>
        <Step1Card
          isSelected={selected === 2}
          onClick={() => {
            setSelected(2)
            dispatch({
              type: 'step_result',
              step_field: 'step1Results',
              step_result: 'Само интернет'
            })
          }}
        >
          <img src={netImg} className='img-fluids mb-3' style={{ height: '70px', width: 'auto' }} alt='Интернет' />
          <h3>Само интернет</h3>
        </Step1Card>
      </div>
    </>
  )
}


export default Step1;