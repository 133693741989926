import React, { useState } from 'react';

import Configurator from '../components/configurator/configurator';
import Layout from '../components/layout';
import { Redirect } from '@reach/router'
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import meta from "../constants/meta-descriptions";
import styled from 'styled-components';

const BackgroundWrapper = styled.div`
  background-color: ${props => props.isFinal ? '#151f41' : 'white'};
  background-image: url(${props => props.bg});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ConfiguratorPage = ({ data }) => {
  const [isFinalBg, setFinalBg] = useState(false);
  return (
    <Redirect noThrow to={`/`} />
  );
}

export const query = graphql`
  query ConfiguratorPage {
    bg: file(relativePath: {eq: "configurator/bg.png"}) {
      childImageSharp {
        fluid(maxHeight: 1400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    finalBg: file(relativePath: {eq: "configurator/final-bg.png"}) {
      childImageSharp {
        fluid(maxHeight: 2800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;


export default ConfiguratorPage;